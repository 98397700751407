import React from 'react';
import './App.css';
import ContactItem from './ContactItem';

function Contact() {
  return (
    <div className='gutter-left-right'>
      <article className='contact-mobile-gutter bubble centered-content'>
        <h2 className='contact-title'>Klaar voor een<br/> nieuw perspectief?</h2>
        <div className= "contact-item-wrapper">
          <ContactItem name="Bjørn Grundel" email="bjorn@dai.nl" phone="06 5127 0317" />
          <ContactItem name="Maddy Ekkelkamp" email="maddy@dai.nl" phone="06 2944 5296" />
        </div>
      </article>
    </div>
  );
}

export default Contact;
function PrivacyPolicy() {
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <iframe
          src="/algemene-voorwaarden.pdf"
          width="100%"
          height="100%"
          style={{ border: "none" }}
          title="Algemene voorwaarden DAI"
        ></iframe>
      </div>
    );
  }
  
  export default PrivacyPolicy;
  